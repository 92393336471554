// js/components/body/searchKeyword.js
/* jshint esversion: 6 */
"use strict";

export function fadeAndSlideUp(element, duration, opacity) {
    // Set CSS custom properties for animation duration
    element.style.setProperty('--fade-duration', duration + 'ms');
    element.style.setProperty('--slide-duration', duration + 'ms');

    // Apply fade effect
    element.style.opacity = opacity;

    // Wait for the fade effect to complete
    setTimeout(function () {
        // Apply slide-up effect
        element.classList.add('slide-up');

        // Wait for the slide-up effect to complete
        setTimeout(function () {
            // Close the alert (remove the element from DOM)
            element.parentNode.removeChild(element);
        }, duration);
    }, duration);
}

export function fadeIn(element, duration = 500) {
    console.log("masuk export function fadeIn ");
    console.log(`isi duration : ${duration}`);
    element.style.display = '';
    element.style.opacity = 0;

    const startTime = new Date().getTime();

    const tick = function () {
        const currentTime = new Date().getTime();
        const elapsed = currentTime - startTime;
        const progress = elapsed / duration;

        element.style.opacity = Math.min(progress, 1);

        if (progress < 1) {
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        }
    };

    tick();
}

export function fadeOut(element, duration = 500) {
    console.log("masuk export function fadeOut ");
    console.log(`isi duration : ${duration}`);
    element.style.opacity = 1;

    const startTime = new Date().getTime();

    const tick = function () {
        const currentTime = new Date().getTime();
        const elapsed = currentTime - startTime;
        const progress = elapsed / duration;

        element.style.opacity = Math.max(1 - progress, 0);

        if (progress < 1) {
            (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
        } else {
            element.style.display = 'none';
        }
    };

    tick();
}

