/* jshint esversion: 6 */
"use strict";

export function publicMenuHeaderBrand1() {
    console.log("masuk publicMenuHeaderBrand1 components on publicHeader js...");
    const topMenuLoginBtnSelector = "button[name='top_menu_login_btn']";
    const topMenuLoginBtnFakeSelector = "button[name='top_menu_login_btn_fake']";
    const topMenuRegisterBtnSelector = "button[name='top_menu_register_btn']";
    const topMenuRegisterBtnFakeSelector = "button[name='top_menu_register_btn_fake']";
    const topMenuLoginBtns = document.querySelectorAll(topMenuLoginBtnSelector);
    const topMenuRegisterBtns = document.querySelectorAll(topMenuRegisterBtnSelector);
    const parentFormSelector = "form.parent_form";

    if (topMenuLoginBtns) {
        console.log("masuk if(topMenuLoginBtns) { in brand1 homepage: ");
        topMenuLoginBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                console.log("masuk topMenuLoginBtns on click");
                const parent = btn.closest(parentFormSelector);
                btn.classList.add("d-none");
                // btn.classList.add("d-none");
                console.log("isi parent on click topMenuLoginBtns");
                console.log(parent);
                const topMenuLoginBtnFake = parent.querySelector(topMenuLoginBtnFakeSelector);
                console.log("isi topMenuLoginBtnFake on click topMenuLoginBtns");
                console.log(topMenuLoginBtnFake);
                topMenuLoginBtnFake.classList.remove("d-none");
            });
        });
    }

    if (topMenuRegisterBtns) {
        console.log("masuk if(topMenuRegisterBtns) { in brand1 homepage: ");
        topMenuRegisterBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                console.log("masuk topMenuRegisterBtns on click");
                const parent = btn.closest(parentFormSelector);
                btn.classList.add("d-none");
                // btn.classList.add("d-none");
                console.log("isi parent on click topMenuLoginBtns");
                console.log(parent);
                const topMenuRegisterBtnFake = parent.querySelector(topMenuRegisterBtnFakeSelector);
                console.log("isi topMenuRegisterBtnFake on click defaultArticleButtons");
                console.log(topMenuRegisterBtnFake);
                topMenuRegisterBtnFake.classList.remove("d-none");
            });
        });
    }
}