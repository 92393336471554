// js/components/initial/myInitialModules.js
/* jshint esversion: 6 */
"use strict";

export function copyTextToClipboard(text) {
    let textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a flash,
    // so some of these are just precautions. However in IE the element
    // is visible whilst the popup box asking the user for permission for
    // the web page to copy to the clipboard.
    //

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
    } catch (err) {
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}

export function tempAlert(msg, duration) {
    let modal = $("<div/>", {
        id: 'modal-dialog',
        class: 'modal fade show'
    });
    let document = $("<div/>", {
        id: 'modal-document',
        class: 'modal-dialog'
    });
    let content = $("<div/>", {
        id: 'modal-content-id',
        class: 'modal-content'
    });
    let content_header = $("<div/>", {
        id: 'content-header-id',
        class: 'border border-success text-light text-center bg-info rounded modal-header',
        html: msg
    });
    let $modal = modal.attr({
        role: "dialog"
    });
    let $modal_dialog = $modal.css(
        "display", "inline-flex"
    );
    let $document = document.attr({
        role: "document"
    });
    setTimeout(function () {
        $('#modal-dialog').remove();
    }, duration);
    $("body").append($modal_dialog);
    $("#modal-dialog").append($document);
    $("#modal-document").append(content);
    $("#modal-content-id").append(content_header);
}

export function jsChecker() {
    console.log("masuk jsChecker js...");
    let isJs = false;
    const jsDisabledSelector = "div[name='js_disabled']";
    const jsEnabledSelector = "div[name='js_enabled']";
    const jsDisabledDivs = document.querySelectorAll(jsDisabledSelector);
    const jsEnabledDivs = document.querySelectorAll(jsEnabledSelector);
    const body = document.body;
    if (body) {
        console.log("masuk if(body){ in jsChecker: ");
        if (jsDisabledDivs) {
            console.log("masuk if (jsDisabledDivs) {in jsChecker: ");
            jsDisabledDivs.forEach(div => {
                div.classList.add("d-none");
            });
        }
        if (jsEnabledDivs) {
            console.log("masuk if (jsEnabledDivs) {in jsChecker: ");
            jsEnabledDivs.forEach(div => {
                div.classList.remove("d-none");
                isJs = true;
            });
        }
    } else {
        console.error('Document body is not available.');
    }
    return isJs;
}

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim(); // No need for jQuery.trim, use the native trim method
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function sameOrigin(url) {
    // Test that a given URL is a same-origin URL
    // URL could be relative, scheme relative, or absolute
    const {host, protocol} = document.location;
    const sr_origin = `//${host}`;
    const origin = `${protocol}${sr_origin}`;
    // Allow absolute or scheme-relative URLs to same origin
    return (
        url === origin ||
        url.slice(0, origin.length + 1) === `${origin}/` ||
        url === sr_origin ||
        url.slice(0, sr_origin.length + 1) === `${sr_origin}/` ||
        // Or any other URL that isn't scheme relative or absolute (i.e., relative)
        !/^(\/\/|http:|https:).*/.test(url)
    );
}

export function csrfSafeMethod(method) {
// These HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

export function csrfTokenInterceptor(config) {
    if (!csrfSafeMethod(config.method) && sameOrigin(config.url)) {
        config.headers.set("X-CSRFToken", csrftoken);
    }
    return config;
}

export async function asyncFetchData(url, options = {}) {
    const {timeout = 7000, ...fetchOptions} = options;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchPromise = fetch(url, {...fetchOptions, signal});

    if (timeout) {
        setTimeout(() => controller.abort(), timeout);
    }

    try {
        const response = await fetchPromise;
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        console.log('masuk response.ok :');
        console.log('isi response :');
        const jsonData = response.json();
        console.log(jsonData);
        return await jsonData;
    } catch (error) {
        if (error.name === 'AbortError') {
            console.error('Fetch aborted');
        } else {
            console.error('Fetch error:', error);
        }
        throw error;
    }
}

export function promiseFetchData(url, options = {}) {
    console.log("masuk function promiseFetchData in MyinitialModules...");
    const {timeout = 7000, ...fetchOptions} = options;
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchPromise = fetch(url, {...fetchOptions, signal});
    console.log("isi url in promiseFetchData MyinitialModules");
    console.log(url);

    if (timeout) {
        console.log("masuk if (timeout) { in promiseFetchData MyinitialModules...");
        setTimeout(() => controller.abort(), timeout);
    }

    return fetchPromise
        .then(response => {
            console.log("masuk .then(response => { in promiseFetchData MyinitialModules...");
            if (!response.ok) {
                console.log("masuk if (!response.ok) {");
                throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        /*.then(data => {
            console.log("masuk Handle the JSON data here");
            // Handle the JSON data here
            console.log(data);
        })*/
        .catch(error => {
            console.error("masuk catch error here in promiseFetchData MyinitialModules");
            if (error.name === 'AbortError') {
                console.error('Fetch aborted');
            } else {
                console.error('Fetch error:', error);
            }
            // throw error; // Re-throw the error to propagate it correctly
        });
}

export function screenChecker(ajaxUrl) {
    console.log("masuk screenChecker : ");
    let dataCsrfValue;
    let retData;
    let status = false;
    let pageCategoryItemUrl = '';
    const divDefaultVideoSelector = "div[name='default_video']";
    const ajaxEndpoitnSelector = "div[name='ajax_endpoint']";
    const ajaxHomepageEndpoitSelector = "div[name='ajax_homepage_endpoint']";
    const dataScreenCheckerSelector = "data-screen-checker-url";
    const csrftoken = getCookie('csrftoken');
    const ajaxEndpointDivs = document.querySelectorAll(ajaxEndpoitnSelector);
    const ajaxHomepageEndpointDivs = document.querySelectorAll(ajaxHomepageEndpoitSelector);
    let divEndpoint;
    if (ajaxHomepageEndpointDivs.length>0) {
        console.log("masuk ajaxHomepageEndpointDivs in screenChecker: ");
        console.log(ajaxHomepageEndpointDivs);
        ajaxHomepageEndpointDivs.forEach(div => {
            console.log("isi div ajaxHomepageEndpointDivs in screenChecker: ");
            console.log(div);
            dataCsrfValue = div.getAttribute('data-csrf');
            divEndpoint = div;
        })
    } else if (ajaxEndpointDivs) {
        console.log("masuk ajaxEndpointDivs in screenChecker: ");
        ajaxEndpointDivs.forEach(div => {
            console.log("isi div ajaxEndpointDivs in screenChecker: ");
            console.log(div);
            dataCsrfValue = div.getAttribute('data-csrf');
            divEndpoint = div;
        })
    } else {
        console.log("masuk else in screenChecker: ");
        return;
    }
    console.log("isi divEndpoint in screenChecker: ");
    console.log(divEndpoint);

    // const ajaxUrl = divNameAjaxEndpoint.getAttribute('data-screen-checker-url');
    /*
    const winWidth = $(window).width();
    const winHeight = $(window).height();
    const currentUrl = $(location).attr('href');
    */
    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;
    // let dataCsrfValue = divEndpoint.getAttribute('data-csrf');
    const screenWidth = divEndpoint.getAttribute('data-screen-width');
    const screenType = divEndpoint.getAttribute('data-screen-type');
    const currentUrl = window.location.href;
    const referrerUrl = document.referrer;
    const screenWidthNoDot = screenWidth.replace(".", "");
    const sessionScreenWidth = parseInt(screenWidthNoDot);
    // const y = winWidth;
    /*console.log("isi sessionScreenWidth : ");
    console.log(sessionScreenWidth);
    console.log("isi winWidth : ");
    console.log(winWidth);
    console.log("isi dataCsrfValue : ");
    console.log(dataCsrfValue);
    console.log("isi csrftoken : ");
    console.log(csrftoken);
    console.log("isi ajaxUrl : ");
    console.log(ajaxUrl);*/
    if (sessionScreenWidth === winWidth) {
        console.log("isi if(screenWidth === winWidth){ : ");
    } else {
        console.log("isi if(screenWidth !== winWidth){ : ");

        const dataSend = {
            current_width: screenWidth,
            current_screen_type: screenType,
            update_width: winWidth,
            CSRF: dataCsrfValue
        };
        promiseFetchData(ajaxUrl, {
            timeout: 5000,
            method: 'POST', // or 'GET', 'PUT', etc.
            headers: {
                'Content-Type': 'application/json', // Specify the content type if sending JSON data
                'X-CSRFToken': csrftoken, // Include CSRF token for POST requests
                'Cache-Control': 'no-cache'
            },
            body: JSON.stringify(dataSend)
        })
            .then(data => {
                console.log("masuk.then(data => {");
                // Process the response data
                console.log(data);
                const dataContent = data.data;
                console.log("isi dataContent in myinitmodules: ");
                console.log(dataContent);
                const dataStatus = data.status;
                console.log("isi dataStatus : ");
                console.log(dataStatus);
                if (dataStatus === 200) {
                    console.log("masuk if(dataContent){");
                    status = true;
                    // const refreshPage = dataContent.refresh_page;
                    const screenType = dataContent.screen_type;
                    pageCategoryItemUrl = dataContent.page_category_item_url;
                    console.log("isi pageCategoryItemUrl : ");
                    console.log(pageCategoryItemUrl);
                    const defaultVideoDivs = document.querySelectorAll(divDefaultVideoSelector);
                    if (defaultVideoDivs) {
                        console.log("masuk if(defaultVideoDivs) { in screenChecker: ");
                        defaultVideoDivs.forEach(div => {
                            div.dataset.url = pageCategoryItemUrl;
                        });
                    }

                } else {
                    console.log("masuk if (status === fail) {");
                    const errorMessage = data.message;
                }
            })
            .catch(error => {
                // Handle any errors that occurred during the fetch
                console.error('Fetch error:', error);
            });

        // console.error('isi retData: in screenChecker before async');
        // console.error(pageCategoryItemUrl);
        /*(async () => {
            try {
                const response = await asyncFetchData(ajaxUrl, {
                    timeout: 5000,
                    method: 'POST', // or 'GET', 'PUT', etc.
                    headers: {
                        'Content-Type': 'application/json', // Specify the content type if sending JSON data
                        'X-CSRFToken': csrftoken, // Include CSRF token for POST requests
                        'Cache-Control': 'no-cache'
                    },
                    body: JSON.stringify(dataSend)
                });
                console.log('response using await with pause:', response);
                const responseJson = await response;
                console.log("isi responseJson in myinitmodules: ");
                console.log(responseJson);
                const dataContent = responseJson.data;
                console.log("isi dataContent in myinitmodules: ");
                console.log(dataContent);
                const dataStatus = responseJson.status;
                console.log("isi dataStatus : ");
                console.log(dataStatus);
                if (dataStatus === "success") {
                    console.log("masuk if(dataContent){");
                    status = true;
                    // const refreshPage = dataContent.refresh_page;
                    const screenType = dataContent.screen_type;
                    pageCategoryItemUrl = dataContent.page_category_item_url;
                    console.log("isi pageCategoryItemUrl : ");
                    console.log(pageCategoryItemUrl);

                } else {
                    console.log("masuk if (status === fail) {");
                    const errorMessage = response.message;
                }
            } catch (error) {
                console.error('Error fetching data with await and pause:', error);
            }
            retData = {
                status: status,
                pageCategoryItemUrl: pageCategoryItemUrl
            };
            // const dataReturn = JSON.stringify(retData);

            return retData;
        })();*/

        // retData = {
        //     status: status,
        //     pageCategoryItemUrl: pageCategoryItemUrl
        // };
        // const dataReturn = JSON.stringify(retData);
        // console.error('isi dataReturn: in screenChecker outside async');
        // console.error(retData);
        // return retData;
    }
    /*retData = {
        'status': status,
        'pageCategoryItemUrl': pageCategoryItemUrl
    };
    console.error('isi retData: in screenChecker after async');
    console.error(retData);
    return retData;*/
}

