// js/components/footer/publicMenuFooterBrand1.js
/* jshint esversion: 6 */
"use strict";

export function publicMenuFooterBrand1() {
    console.log("masuk publicMenuFooterBrand1 components on publicFooter js...");
    const aboutBtnSelector = "button[name='about_btn']";
    const aboutBtnFakeSelector = "button[name='about_btn_fake']";
    const contactUsBtnSelector = "button[name='contact_us_btn']";
    const contactUsBtnFakeSelector = "button[name='contact_us_btn_fake']";
    const careerBtnSelector = "button[name='career_btn']";
    const careerBtnFakeSelector = "button[name='career_btn_fake']";
    const newsBtnSelector = "button[name='news_btn']";
    const newsBtnFakeSelector = "button[name='news_btn_fake']";
    const investorBtnSelector = "button[name='investor_btn']";
    const investorBtnFakeSelector = "button[name='investor_btn_fake']";
    const parentFormSelector = "form.parent_form";

    const aboutBtns = document.querySelectorAll(aboutBtnSelector);
    const contactUsBtns = document.querySelectorAll(contactUsBtnSelector);
    const careerBtns = document.querySelectorAll(careerBtnSelector);
    const newsBtns = document.querySelectorAll(newsBtnSelector);
    const investorBtns = document.querySelectorAll(investorBtnSelector);


    if (aboutBtns) {
        console.log("masuk if(aboutBtns) { in public footer: ");
        aboutBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                console.log("masuk aboutBtns on click");
                const parent = btn.closest(parentFormSelector);
                btn.classList.add("d-none");
                // btn.classList.add("d-none");
                console.log("isi parent on click aboutBtns");
                console.log(parent);
                const aboutBtnFake = parent.querySelector(aboutBtnFakeSelector);
                console.log("isi aboutBtnFake on click aboutBtns");
                console.log(aboutBtnFake);
                aboutBtnFake.classList.remove("d-none");
            });
        });
    }

    if (contactUsBtns) {
        console.log("masuk if(contactUsBtns) { in public footer: ");
        contactUsBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                console.log("masuk contactUsBtns on click");
                const parent = btn.closest(parentFormSelector);
                btn.classList.add("d-none");
                // btn.classList.add("d-none");
                console.log("isi parent on click contactUsBtns");
                console.log(parent);
                const contactUsBtnFake = parent.querySelector(contactUsBtnFakeSelector);
                console.log("isi contactUsBtnFake on click contactUsBtns");
                console.log(contactUsBtnFake);
                contactUsBtnFake.classList.remove("d-none");
            });
        });
    }

    if (careerBtns) {
        console.log("masuk if(careerBtns) { in public footer: ");
        careerBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                console.log("masuk careerBtns on click");
                const parent = btn.closest(parentFormSelector);
                btn.classList.add("d-none");
                // btn.classList.add("d-none");
                console.log("isi parent on click careerBtns");
                console.log(parent);
                const careerBtnFake = parent.querySelector(careerBtnFakeSelector);
                console.log("isi careerBtnFake on click contactUsBtns");
                console.log(careerBtnFake);
                careerBtnFake.classList.remove("d-none");
            });
        });
    }

    if (newsBtns) {
        console.log("masuk if(newsBtns) { in public footer: ");
        newsBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                console.log("masuk newsBtns on click");
                const parent = btn.closest(parentFormSelector);
                btn.classList.add("d-none");
                // btn.classList.add("d-none");
                console.log("isi parent on click newsBtns");
                console.log(parent);
                const newsBtnFake = parent.querySelector(newsBtnFakeSelector);
                console.log("isi newsBtns on click contactUsBtns");
                console.log(newsBtns);
                newsBtnFake.classList.remove("d-none");
            });
        });
    }

    if (investorBtns) {
        console.log("masuk if(investorBtns) { in public footer: ");
        investorBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                console.log("masuk investorBtns on click");
                const parent = btn.closest(parentFormSelector);
                btn.classList.add("d-none");
                // btn.classList.add("d-none");
                console.log("isi parent on click investorBtns");
                console.log(parent);
                const investorBtnFake = parent.querySelector(investorBtnFakeSelector);
                console.log("isi investorBtnFake on click contactUsBtns");
                console.log(investorBtnFake);
                investorBtnFake.classList.remove("d-none");
            });
        });
    }
}
