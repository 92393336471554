// js/utils/event_helpers.js
/* jshint esversion: 6 */
"use strict";

/**
 * Utility function to add multiple event listeners
 * @params:
 * {element} str element - The html element.
 * {events} str - event listener.
 * {handler} str - handler event function.
 * @return {string} - The capitalized string.
 */
export function addMultipleEventListeners(element, events, handler) {
    events.split(' ').forEach(event => {
        element.addEventListener(event, handler);
    });
}

export function addInputEventListeners(selector, events, handler) {
    // Select all input elements
    let inputElements = document.querySelectorAll(selector);

    // Add event listeners to each input element
    inputElements.forEach(inputElement => {
        addMultipleEventListeners(inputElement, events, handler);
    });
}

export function addButtonEventListeners(selector, events, handler) {
    // Select all input elements
    const buttonsElement = document.querySelectorAll(selector);

    // Add event listeners to each input element
    buttonsElement.forEach(button => {
        addMultipleEventListeners(button, events, handler);
    });
}

export function addFormEventListeners(selector, events, handler) {
    // Select all input elements
    const formsElement = document.querySelectorAll(selector);

    // Add event listeners to each input element
    formsElement.forEach(formElement => {
        addMultipleEventListeners(formElement, events, handler);
    });
}
