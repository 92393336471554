// js/components/body/heroesGroup.js
/* jshint esversion: 6 */
"use strict";

export function heroesGroup() {
    console.log("masuk heroes group js...");
    const heroButtonSelector = "button[name='hero_button']";
    const parentFormSelector = 'form.parent_form';
    const heroButtons = document.querySelectorAll(heroButtonSelector);
    if (heroButtons) {
        console.log("masuk if(heroButtons) { in heroesGroup: ");
        heroButtons.forEach(button => {
            button.addEventListener('click', function () {
                // Assuming $parent_form is a class, replace it with the appropriate selector
                const parentForm = this.closest(parentFormSelector); // Replace '.parent_form' with the correct selector
                // If $parent_form was meant to be a variable, make sure to define it before using it.
                this.classList.add("d-none");
                parentForm.querySelector("button[name='hero_button_fake']").classList.remove("d-none");
            });
        });
    }
}

